import { useEffect } from 'react'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import Head from 'next/head'

import '../styles/index.css'

import { analytics } from '../utils/analytics'
import { initSentry } from '../utils/monitoring'

initSentry()

export default function App({ Component, pageProps }: AppProps): JSX.Element {
  const router = useRouter()

  useEffect(() => {
    analytics.page()
  }, [router.pathname])

  return (
    <>
      <Head>
        <link
          href="/favicon-32x32.png"
          rel="icon"
          sizes="32x32"
          type="image/png"
        />
        <link
          href="/favicon-16x16.png"
          rel="icon"
          sizes="16x16"
          type="image/png"
        />
      </Head>
      <Component {...pageProps} />
    </>
  )
}
