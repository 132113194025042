import { Integrations } from '@sentry/tracing'
import * as Sentry from '@sentry/node'

export function initSentry() {
  if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    Sentry.init({
      autoSessionTracking: true,
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
      enabled: process.env.NODE_ENV === 'production',
      environment: process.env.NEXT_PUBLIC_APP_ENV,
      integrations: [new Integrations.BrowserTracing()],
      release: process.env.NEXT_PUBLIC_COMMIT_SHA,
      tracesSampleRate: 1.0,
    })
  }
}
